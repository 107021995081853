import { OutboundLink } from 'gatsby-plugin-google-gtag'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import './styles.scss'

const CampaignVideo: React.FC = () => {
  return (
    <section className="container">
      <div className="row">
        <h3 className=" text-primary text-center p-layout-4"> 
          <FormattedMessage id="campaign.video.title" />
          <FormattedMessage id="campaign.video.know_more" />
          <OutboundLink href="http://bit.ly/3yzP56R">
            <FormattedMessage id="campaign.video.one_pager" />
          </OutboundLink>
        </h3>
      </div>
      <div className="row pb-layout-4">
        <div className="col-12">
        <iframe className="campaign-video"
         src="https://www.youtube.com/embed/RhEQmDUGb14"
         title="YouTube video player"
         frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
         allowfullscreen></iframe>
        </div>
      </div>
    </section>
  )
}

export default CampaignVideo
import React from 'react'

const CampaignFullHero: React.FC = () => {
  return (
    <section className={`container`} >
      <div className="row hero"></div>
    </section>
  )
}

export default CampaignFullHero
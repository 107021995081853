import React from 'react'
import { graphql } from 'gatsby';

import { LocaleTypes } from '../data/locales';
import IndexLayout from '../layouts'
import SEO from '../components/SEO/SEO';
import CampaignHero from '../components/Campaign/CampaignHero/CampaignHero';
import CampaignForm from '../components/Campaign/CampaignForm/CampaignForm';
import CampaignBenefits from '../components/Campaign/CampaignBenefits/CampaignBenefits';
import CampaignSteps from '../components/Campaign/CampaignSteps/CampaignSteps';
import Footer from '../components/Footer/Footer';
import CampaignFullHero from '../components/Campaign/CampaignFullHero/CampaignFullHero';
import CampaignVideo from '../components/Campaign/CampaignVideo/CampaignVideo';
import CampaignFooterBan from '../components/Campaign/CampaignFooterBan/CampaignFooterBan';
import CampaignFormPink from '../components/Campaign/CampaignFormPink/CampaignFormPink';
import CampaignFooterBanVtex from '../components/Campaign/CampaignFooterBanVtex/CampaignFooterBanVtex';

interface CampaignProps {
  data: {
    title: string;
    subtitle: string;
    heroImage: {
      localFile: {
        url: string
      }
    }
    functionalities: string[];
    benefitTitle: string;
    benefits: string;
    titleSummary: string;
    summary: string;
    steps: string[]
    slug: string;
    fullHeroImage: boolean;
  }
  pathContext: {
    locale: LocaleTypes;
  };
  location: Location;
}
const campaignTemplate: React.SFC<CampaignProps> = ({ data, pathContext, location }) => {
  const { contentfulCampaign: dataCampaign }: any = data
  const content = () => {
    return (
      <>
        {/* campaign SEO */}
        <SEO title={dataCampaign.title} />
        {/* campaign hero */}
        {
          dataCampaign && dataCampaign.fullHeroImage ? 
            <section className={`${dataCampaign.slug}`}>
              <CampaignFullHero />
            </section>
          :
            <CampaignHero 
              title={dataCampaign.title}
              subtitle={dataCampaign.subtitle}
              imgHero={dataCampaign.heroImage.localFile.url} slug={dataCampaign.slug} />
        }
        {/* campaign video */}
        {
          dataCampaign.slug === 'wcf-2023' &&
          <CampaignVideo />
        }
        {/* Campaign form */}
        {
          dataCampaign.slug === 'vtex' ?
          <CampaignFormPink titleSummary={dataCampaign.titleSummary} summary={dataCampaign.summary} functionalities={dataCampaign.functionalities || []} slug={dataCampaign.slug} />
          :
          <CampaignForm titleSummary={dataCampaign.titleSummary} summary={dataCampaign.summary} functionalities={dataCampaign.functionalities || []} slug={dataCampaign.slug} />
        }
        {/* Campaign benefits */}
        {
          dataCampaign.benefits &&
            <CampaignBenefits titleBenefit={dataCampaign.benefitTitle} benefits={dataCampaign.benefits} />
        }
        {/* campaign steps */}
        {
          dataCampaign.steps &&
            <CampaignSteps steps={dataCampaign.steps} slug={dataCampaign.slug} />
        }
        {/* banner footer */}
        {
          dataCampaign.slug === 'wcf-2023' &&
          <CampaignFooterBan />
        }
        {
          dataCampaign.slug === 'vtex' &&
          <CampaignFooterBanVtex variant={dataCampaign.slug === 'vtex' ? 'secondary' : 'primary'} />
        }
        <Footer theme={'primary'} />
      </>
    )
  }

  return (
    <IndexLayout
      navbarTheme={dataCampaign.slug === 'wcf-2023' ? 'light' : 'default'}
      render={ content }
      locale={ pathContext.locale }
      location={ location}
    />
  )
}

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulCampaign(slug: { eq: $slug }, node_locale: { eq: $locale }) {
    title
    subtitle
    heroImage {
      localFile {
        url
      }
    }
    functionalities
    benefitTitle
    benefits
    titleSummary
    summary
    steps
    slug
    node_locale
    fullHeroImage
  }
  }
`

export default campaignTemplate
import React, {useState, useEffect, useContext} from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { FormattedMessage } from 'react-intl'
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { defaultFormatValues } from '../../../data/defaultFormat'
import ModalMessage from '../../Form/Modal'

import './styles.scss'

interface FormProps {
  slug: string;
}

const Form:React.FC<FormProps> = ({slug}) => {

  const [modalIsOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState()
  const { register, formState: { errors }, handleSubmit } = useForm<IFormInput>();

  const countriesList:Array<string> = [
    'Chile',
    'Colombia',
    'Ecuador',
    'Mexico',
    'Peru'
  ];


  const onSubmit: SubmitHandler<IFormInput> = data => {
    const date = new Date();
    const name = data.name.split(' ')

    const params = `
      name=${name} 
      &email=${data.email} 
      &phone=${value} 
      &company=${data.enterprise}
      &origin_country=${data.originCountry}
      &url=${data.website} 
      &date=${date.toISOString()}
      &LeadSource=Digital
      &Campana__c=LanzVTEX
      &channel=GooglePaid
    `
    axios.post('https://hooks.zapier.com/hooks/catch/1396001/3y7m75y/?' + params, {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        setIsOpen(true)
      }
    })
  }
  return (
    <div className="form-lead-campaign">
      <div className="container form-lead-container">
        <div className="row">
          <form id="kushki-form" className="form-lead-form-campaign" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.name" /> <b className="input-required">*</b></label>
                <input placeholder="Pedro Pascal" {...register("name", { required: true, pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u })} className={errors.name ? 'input-error' : 'input'}/>
                {errors.name && <p className="error-message"><FormattedMessage id="form.errors.err-name" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.email" /> <b className="input-required">*</b></label>
              <input placeholder="mail@kushki.com" {...register("email", { required: true, pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,  })} className={errors.email ? 'input-error' : 'input'}/><br />
              {errors.email && <p className="error-message"><FormattedMessage id="form.errors.err-email" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.phone" /> <b className="input-required">*</b></label>
                <div className="phone-input">
                  <PhoneInput value={value} onChange={ setValue } defaultCountry="CO" placeholder="5512345678"/>
                </div>
              </div>
            </div>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.company" />  <b className="input-required">*</b></label>
              <input className={errors.enterprise ? 'input-error' : 'input'} placeholder="Kushki Pagos" {...register("enterprise", {required: true /*, validate: (value) => {return value === "";}*/})} /><br />
              {errors.enterprise && <p className="error-message"><FormattedMessage id="form.errors.err-company" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.website" />  <b className="input-required">*</b></label>
                <input className={errors.website ? 'input-error' : 'input'} placeholder="www.kushkipagos.com" {...register("website", {required: true, pattern: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi})} /><br />
                {errors.website && <p className="error-message"><FormattedMessage id="form.errors.err-website" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <div className="input-order col-md-12 col-lg-12 col-sm-12">
              <label className="campaign_input-label text-white"><FormattedMessage id="form.questions.origin-country" /> <b className="input-required">*</b></label>
              <select className={errors.originCountry ? 'input-error' : 'input'} {...register("originCountry", { required: "select one option" })}>
                  <option></option>
                  {countriesList.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
                {errors.originCountry && <p className="error-message"><FormattedMessage id="form.errors.err-origin" /></p>}
                <br />
                <OutboundLink className="text-white" href="https://www.kushkimundial.com/">
                  <FormattedMessage id="form.questions.no-country" values={defaultFormatValues} />
                </OutboundLink>
              </div>
            </div>
            <ModalMessage modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} page="leadform" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
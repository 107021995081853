import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

import './styles.scss'

const CampaignFooterBan = () => {
  return (
    <section className="position-relative d-flex justify-content-center campaign-section-banner">
      <div className="bg-middle-blue"/>
        <div className="footer-cta-section text-light mx-layout-4 mx-lg-0">
          <div className="container" >
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center align-items-center order-1 order-lg-2">
                <div className="text-center d-lg-block d-none ">
                  <h2 className="mt-layout-4 mt-lg-0 text-white">
                      <FormattedMessage id="campaign.footer.title" values={defaultFormatValues} />
                  </h2>
                  <LocalizedLink to='/work-with-us' className="btn--green">
                    <FormattedMessage id="campaign.cta"/>
                  </LocalizedLink>
                </div>
                <div className="text-center d-lg-none d-block">
                  <LocalizedLink to='/work-with-us' className="btn--green">
                    <FormattedMessage id="campaign.cta"/>
                  </LocalizedLink>
                  <h2 className="mt-layout-4 mt-lg-0 text-white">
                      <FormattedMessage id="campaign.footer.title" values={defaultFormatValues} />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default CampaignFooterBan
import React from 'react'
import { FormattedMessage } from 'react-intl'
import laptopImg from './assets/images/laptop-wfc.png'
import mujerImg from './assets/images/mujer.png'
import Form from './Form';

import './styles.scss'

interface CampaignFormPinkProps {
  titleSummary?: string;
  summary: string;
  functionalities: string[]
  slug: string;
}

const CampaignFormPink: React.FC<CampaignFormPinkProps> = ({titleSummary, summary, functionalities, slug}) => {
  return (
    <section className="campaign-bg_form_pink mb-layout-4">
      <div className="container pb-layout-2 container-form">
        <div className="row pt-layout-6">
          <div className="col-md-6 col-12 d-flex align-items-center  ">
            <div className={slug === 'vtex' ? 'vtex-text' : ''} >
              <h2 className="text-white campaign-title_form" >{titleSummary}</h2>
              <p className="text-white campaign-text_form" >{summary}</p>
            </div>
          </div>
          <div className="col-md-6 col-12 pb-layout-2">
            <h3 className="text-white text-center"><FormattedMessage id="campaign.sign_up" /></h3>
            <Form slug={slug} />
          </div>
        </div>
        {
          functionalities.length > 0 &&
            <div className="campaign-func_block mb-4 pb-layout-1">
              <div className="title_functionalities-pink p-4">
                <p className="text-white">
                  <FormattedMessage id="campaign.functionalities" />
                </p>
              </div>
              <div className="my-3 container-list">
                <ul className="row my-3 campaign_ul-list">
                  {
                    functionalities.map((item, i) => (
                      <li className="col-md-6" key={i}>
                        {item}
                      </li>
                      )
                    )
                  }
                </ul>
              </div>
            </div>
        }
      </div>
    </section>
  )
}

export default CampaignFormPink
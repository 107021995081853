import React from 'react'

import BadgeImg from './assets/images/badge.inline.svg';
import EngineImg from './assets/images/engine.inline.svg';
import ShieldImg from './assets/images/shield.inline.svg';
import Engine2Img from './assets/images/engine2.inline.svg';

import './styles.scss'

interface CampaignBProps {
  titleBenefit: string;
  benefits: string[];
}
const CampaignBenefits:React.FC<CampaignBProps> = ({titleBenefit, benefits}) => {


  const getBenefits = () => {

    if(benefits.length > 3) {
      return (
        <>
          <div className="col-md-3 campaign_benefit-block px-layout-4">
            <BadgeImg />
            <p className="text-center">{benefits[0]}</p>
          </div>
          <div className="col-md-3 campaign_benefit-block px-layout-4">
            <EngineImg />
            <p className="text-center">{benefits[1]}</p>
          </div>
          <div className="col-md-3 campaign_benefit-block px-layout-4">
            <ShieldImg />
            <p className="text-center">{benefits[2]}</p>
          </div>
          <div className="col-md-3 campaign_benefit-block px-layout-4">
            <Engine2Img />
            <p className="text-center">{benefits[3]}</p>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="col-md-4 campaign_benefit-block px-layout-4">
            <BadgeImg />
            <p className="text-center">{benefits[0]}</p>
          </div>
          <div className="col-md-4 campaign_benefit-block px-layout-4">
            <EngineImg />
            <p className="text-center">{benefits[1]}</p>
          </div>
          <div className="col-md-4 campaign_benefit-block px-layout-4">
            <ShieldImg />
            <p className="text-center">{benefits[2]}</p>
          </div>
        </>
      )
    }
  }

  return (
    <section className="container py-layout-3">
      <div className="row">
        <div className="col-12 mt-layout-6 mb-layout-4">
          <h2 className="text-primary text-center">{titleBenefit}</h2>
        </div>
        { getBenefits() }
      </div>
    </section>
  )
}

export default CampaignBenefits
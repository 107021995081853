import React from 'react'
import { FormattedMessage } from 'react-intl';

import PcImg from './assets/images/pc.inline.svg'
import BacklogImg from './assets/images/backlog.inline.svg'
import StoreImg from './assets/images/store.inline.svg'

import FileImg from './assets/images/file.inline.svg'
import Pc2Img from './assets/images/pc2.inline.svg'
import Back2logImg from './assets/images/backlog2.inline.svg'



import './styles.scss'
import { OutboundLink } from 'gatsby-plugin-google-gtag';


interface CampaignStepsProps {
  steps: string[];
  slug?: string;
}

const CampaignSteps: React.FC<CampaignStepsProps> = ({steps, slug}) => {
  return (
    <section className="container">
      <h4 className="text-center text-primary my-layout-5"><FormattedMessage id="campaign.title_steps" /></h4>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div className="campaign_card-step step-color-1">
            { slug === 'vtex' ?
              <FileImg className="campaign-img_steps " />
              :
              <PcImg className="campaign-img_steps " />
            }
            <p className="text-white text-center mt-5">{steps[0]}</p>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div className="campaign_card-step step-color-2">
            {
              slug === 'vtex' ?
                <Pc2Img className="campaign-img_steps " />
              :
                <BacklogImg className="campaign-img_steps " />
              }
            <p className="text-white text-center mt-5">{steps[1]}</p>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div className="campaign_card-step step-color-3">
            {
              slug === 'vtex' ?
                <Back2logImg className="campaign-img_steps " />
              :
                <StoreImg className="campaign-img_steps " />
            }
            <p className="text-white text-center mt-5">{steps[2]}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-layout-4">
          {
            slug === 'vtex' ? 
            <p className="text-primary text-center" ><FormattedMessage id="campaign.text_steps_vtex" /></p>
            :
              <p className="text-primary text-center" ><FormattedMessage id="campaign.link_steps" />
                <OutboundLink
                href="https://docs.kushki.com/"
                target="_blank">
                  <strong>
                    <FormattedMessage id="campaign.link" />
                  </strong>
                </OutboundLink>
              </p>
          }
        </div>
      </div>
    </section>
  )
}

export default CampaignSteps
import React, {useState, useEffect, useContext} from 'react';

import ImgKushkiShop from './assets/images/kushki-shop.inline.svg'
import ImgKushkiVtext from './assets/images/vtex-kushki.inline.svg'
import Ecflag from './assets/images/ecflag.inline.svg'
import Coflag from './assets/images/coflag.inline.svg'
import Clflag from './assets/images/clflag.inline.svg'
import Peflag from './assets/images/peflag.inline.svg'
import Mxflag from './assets/images/mxflag.inline.svg'
import LocaleContext from '../../../contexts/LocaleContext';

import './styles.scss'
import { FormattedMessage } from 'react-intl'

interface CampaignHeroProps {
  title: string,
  subtitle: string,
  imgHero: string,
  slug: string
}

const CampaignHero: React.FC<CampaignHeroProps> = ({title, subtitle, imgHero, slug}) => {
  const localeCode = useContext(LocaleContext);

  return (
    <section className="hero bg-campaign-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 py-layout-3 campaign-title_hero">
            { 
              slug === 'plugins' &&
              <ImgKushkiShop className="campaign-hero-banner" />
            }
            {
              slug === 'vtex' && 
              <ImgKushkiVtext className="campaign-hero-banner ml-xm-layout-4" />
            }
            <h1 className={`text-white campaing-title ${localeCode === 'en' ? "eng" : ""}`}>
              {title}
            </h1>
            <p className="text-white">{subtitle}</p>
            <p className="text-white"><FormattedMessage id="campaign.available" /></p>
            <div className="campaign-group_flags">
              <Ecflag className="flag m-0" />
              <Coflag className="flag" />
              <Clflag className="flag" />
              <Peflag className="flag" />
              <Mxflag className="flag" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <img className="campaign-hero_img" src={imgHero} alt="Plugins-Hero" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CampaignHero
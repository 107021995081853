import React from 'react'

import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'

import './styles.scss'

interface CampaignFooterBanVtexProps {
  variant?: 'primary' | 'secondary' | 'gray' | 'dark';
}

const CampaignFooterBanVtex:React.FC<CampaignFooterBanVtexProps> = ({variant}) => {

  const scrollToBenefits = () =>{
    window.scrollTo({
      top: 760, 
      behavior: 'smooth'
    });
  };
  return (
    <section className={`d-flex justify-content-center campaign-section-banner_${variant}`}>
        <div className={`footer-cta-section_${variant} text-light mx-lg-layout-4 mx-lg-0`}>
          <div className="container" >
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center align-items-center">
                <div className="text-center p-lg-layout-4">
                  <h2 className="mt-layout-4 mt-lg-0 text-white px-lg-layout-2">
                      <FormattedMessage id="campaign.footer.vtex.title" values={defaultFormatValues} />
                  </h2>
                  <button className="btn--green" onClick={scrollToBenefits}>
                    <FormattedMessage
                      id="index.hero.button"
                      values={defaultFormatValues}
                      />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default CampaignFooterBanVtex